import {useState} from "react";
import MenuBuilder from "../builder/MenuBuilder";
import requestPermissions from "../api/requestPermissions";

enum MyPermissionsStatusEnum {
    INITIATED = "initiated",
    PENDING = "pending",
    DONE = "done",
}

const MenuPage = () => {
    const [state, setState] = useState({menu:[], permissions: [], status: MyPermissionsStatusEnum.INITIATED})

    const setStateSuccess = (permissions, menu) => {
        setState({
            status: MyPermissionsStatusEnum.DONE,
            permissions: permissions,
            menu: menu
        })
    }

    const setStateError = () => {
        setState({
            ...state,
            ...{status: MyPermissionsStatusEnum.INITIATED},
        })
    }

    const setStatePending = () => {
        setState({
            ...state,
            ...{status: MyPermissionsStatusEnum.PENDING},
        })
    }

    if (state.status === MyPermissionsStatusEnum.INITIATED) {
        requestPermissions(setStatePending, setStateSuccess, setStateError)
    }

    return (new MenuBuilder(state.menu)).build();
};

export default MenuPage;