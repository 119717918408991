type Props = {
    children: JSX.Element[],
    active: boolean,
    icon: JSX.Element,
    title: string,
};
const Submenu = ({ children, active, icon, title }: Props) => {
    const classNames: string[] = [
        'menu-item menu-accordion',
    ];
    const subClassNames: string[] = [
        'menu-link'
    ];
    const containerClassNames: string[] = [
        'menu-sub',
        'menu-sub-accordion',
    ]
    if (active) {
        classNames.push('show')
        subClassNames.push('active')
        containerClassNames.push('show')
    }

    return <div data-kt-menu-trigger="click" className={classNames.join(' ')}>
        <span className={subClassNames.join(' ')}>
            <span className="menu-icon">
                {icon}
            </span>
            <span className="menu-title">{title}</span>
            <span className="menu-arrow"></span>
        </span>
        <div className={containerClassNames.join(' ')}>
            {children}
        </div>
    </div>
};

export default Submenu;