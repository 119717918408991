import React from 'react'
import ReactDom from 'react-dom'
import MenuPage from "./pages/MenuPage";

function App() {
    return <MenuPage />
}

ReactDom.render(
        <App />,
    document.querySelector('#main-dynamic-menu')
)
