import axios from "axios";
import {string} from "yup";
import {symlinkSync} from "fs";

declare global {
    interface Window {
        ApiRoutes: any;
        MyPermissions: any;
    }
}

type Permission = {
    name: string;
    level: Number;
}

const cleanPermissions = (permissions: Permission[]) : Object => {
    const result: Object = {}
    for (const p of permissions) {
        result[p.name] = p.level
    }
    return result
}

const requestPermissions = (setStatePending, setStateSuccess, setStateError) => {
    setStatePending()
    axios.get(window.ApiRoutes.myPermissions()).then(response => {
        window.MyPermissions = cleanPermissions(response.data.permissions)
        return setStateSuccess(response.data.permissions, response.data.menu)
    }).catch(() => {
        setStateError()
    })
}

export default requestPermissions