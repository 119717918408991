type Props = {
    children: JSX.Element[],
};
const Menu = ({ children }: Props) => {
    const classNames = [
        'menu',
        'menu-column',
        'menu-title-gray-800',
        'menu-state-title-primary',
        'menu-state-icon-primary',
        'menu-state-bullet-primary',
        'menu-arrow-gray-500',
    ];

    return <div className={classNames.join(' ')} id="#kt_aside_menu" data-kt-menu="true">
        {children}
    </div>;
};

export default Menu;