import _ from 'lodash';

/*
This class is sensitive to the URL convention changes. Yii2 framework has a feature of pretty URL
that is not used by default. This class must be changed in case it will be enabled
 */
export default class ActiveStateService {
    constructor() {
    }

    private getUrl(): string {
        return window.location.search
    }

    public checkActive(rule: string): boolean {
        const rules = this.getCleanArrayFromUriString(rule)
        const current = this.getCleanArrayFromUriString(this.getUrl())

        for (const r of rules) {
            if (!current.includes(r)) {
                return false
            }
        }

        return true
    }

    private getCleanArrayFromUriString(uri: string): string[] {
        uri = this.removeGarbagePrefix(uri)
        uri = decodeURI(this.unescape(uri))
        const uriArray = uri.split('&')
        return uriArray.map((el) => this.cleanSearchElement(el))
    }

    private cleanSearchElement(el: string): string {
        return _.trim(el, '/ ')
    }

    private removeGarbagePrefix(uri: string) {
        if (uri != null) {
            return uri.replace(/^\?r=/, '')
        } else {
            return uri
        }
    
    }

    // standard unescape/escape are deprecated and I use this self-made instead just in case
    private unescape(uri: string): string {
        if (uri != null) {
            return uri.replace(/%2F/g, '/')
        } else {
            return uri
        }
    }
}