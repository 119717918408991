import Menu from "../components/menu";
import Submenu from "../components/menu-submenu";
import Item from "../components/menu-item";
import ActiveStateService from "./ActiveStateService";
import MenuIconService from "./MenuIconService";

type MenuNode = {
    children: MenuNode[],
    link: string,
    name: string,
    key: string,
}

class ElementWithMeta {
    constructor(element: JSX.Element, active: boolean) {
        this.element = element;
        this.active = active;
    }

    public element: JSX.Element;
    public active: boolean;
}

export default class MenuBuilder {
    menu: MenuNode[];
    activeChecker: ActiveStateService;
    iconService: MenuIconService;

    constructor(menu: MenuNode[]) {
        this.menu = menu;
        this.iconService = new MenuIconService();
    }

    public build(): JSX.Element {
        this.activeChecker = new ActiveStateService()
        return this.makeMenu()
    }

    private makeMenu() {
        const children: JSX.Element[] = [];
        const iterable: MenuNode[] = Array.isArray(this.menu) ? this.menu : Object.values(this.menu)
        for (const c of iterable) {

            children.push(this.makeNode(c).element)
        }
        return <Menu>{children}</Menu>
    }

    private makeNode(node: MenuNode): ElementWithMeta {
        if (node.children.length > 0) {
            return this.makeSubmenuNode(node)
        }
        return this.makeItemNode(node)
    }

    private makeSubmenuNode(node: MenuNode): ElementWithMeta {
        const renderedChildren: JSX.Element[] = [];
        let active = false;
        for (const c of node.children) {
            const childEl = this.makeNode(c)
            if (childEl.active) {
                active = true
            }
            renderedChildren.push(childEl.element);
        }
        return new ElementWithMeta(<Submenu key={node.name} active={active} icon={this.iconService.get(node.key)} title={node.name}>{renderedChildren}</Submenu>, active)
    }

    private makeItemNode(node: MenuNode): ElementWithMeta {
        const active = this.activeChecker.checkActive(node.link)
        return new ElementWithMeta(<Item key={node.name} active={active} icon={this.iconService.get(node.key)} link={this.makeLink(node.link)} title={node.name === 'Feedback Admin' ? 'Feedback' : node.name} line={false} />, active)
    }

    private makeLink(link: string) {
        return "/index.php?r=" + link
    }
}