type Props = {
    active: boolean,
    icon: JSX.Element,
    link: string,
    title: string,
    line: boolean,

};
const Item = ({ active, icon, link, title }: Props) => {
    const classNames: string[] = [
        'menu-item',
    ];
    const linkClassNames: string[] = [
        'menu-link'
    ];
    const lineClassName: string[] = [
        'menu-sub',
        'menu-sub-accordion',
    ]
    if (active) {
        classNames.push('active-menu-item')
        linkClassNames.push('active')
        lineClassName.push('menu-line')
    }

    return <div className={classNames.join(' ')}>
        <a className={linkClassNames.join(' ')} href={link}>
        <span className="menu-icon">
            {icon}
        </span>
            <span className="menu-title">{title}</span>
        </a>
        <div className={lineClassName.join(' ')}></div>
    </div>
};

export default Item;